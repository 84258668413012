import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from "gatsby";
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';

const useStyles = makeStyles(theme => ({
  notaBene: {
    borderTop: `1px solid ${theme.palette.yellow.main}`,
    margin: `${theme.spacing(3)}px ${theme.spacing(0)}px`,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(0)}px`,
    "& p": {
      fontSize: `${theme.spacing(1.5)}px`,
      paddingBottom: `${theme.spacing(1)}px`,
    },
  },
  hyperlink: {
    textDecoration: "none",
    fontWeight: 700,
    "&:hover": {
      backgroundColor: theme.palette.yellow.main,
      color: theme.palette.yellow.contrastText,
    },
    "&:visited": {
      color: "initial"
    },
  },
}));

const Contact = () => {
  const classes = useStyles();

  return (
    <Layout pageName="Contact">
      <Typography variant="h1">Quelque chose à nous dire ? </Typography>
      <Typography variant="h2">On t'écoute</Typography>
      <ContactForm />
      <div className={classes.notaBene}>
        <Typography variant="body2">
          Surboom traite les données recueillies pour pouvoir gérer votre demande.
          <br />
          Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, rendez-vous sur la page 
          <Link
            role="button"
            className={classes.hyperlink}
            cover
            direction="bottom"
            top="entry"
            to="/informations/personal-data-management"
            bg="black"
          >
            “Gestion des données personnelles”
          </Link>
        .</Typography>
      </div>
    </Layout>
  );
};
export default Contact;