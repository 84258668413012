import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: `${theme.spacing(6)}px 0px`,
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    },
  },
  contact_input_button: {
    margin: `${theme.spacing(6)}px 0px`,
    textTransform: "uppercase"
  }
})); 

// function encode(data) {
//   return Object.keys(data)
//     .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
//     .join('&')
// }

const ContactForm = () => {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   const form = e.target
  //   fetch('/', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  //     body: encode({
  //       'form-name': form.getAttribute('name'),
  //       ...state,
  //     }),
  //   })
  //     .then(() => navigate(form.getAttribute('action')))
  //     .catch((error) => alert(error))
  // }

  const classes = useStyles();
  return (
    <Grid 
      className={classes.container}
      container
      direction="row"
      alignItems= "center"
      justifyContent="center"
      alignContent="center"
    >
      <form 
        className={classes.container} 
        name="contact" 
        method="post" 
        data-netlify="true" 
        data-netlify-honeypot="bot-field" 
        action="/thanks/"
      >
        <input type="hidden" name="form-name" value="contact" />
        <Grid item xs={12} md={8}>
          <Grid 
          container 
          direction="row"
          alignItems= "center"
          alignContent="center"
          justifyContent="center"
          spacing={2}
          >
            <Grid item xs={12} md={6}>
              <TextField 
                className={classes.contact_input}
                label="Ton prénom" 
                placeholder=""
                fullWidth
                margin="normal"
                variant="outlined"
                required
                onChange={handleChange}
                name="firstName"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField 
                className={classes.contact_input}
                label="Ton nom" 
                placeholder=""
                fullWidth
                margin="normal"
                variant="outlined"
                required
                onChange={handleChange}
                name="name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                className={classes.contact_input}
                label="Ton adresse mail" 
                placeholder="name@example.com"
                fullWidth
                margin="normal"
                variant="outlined"
                required
                onChange={handleChange}
                name="email"
            />
          </Grid>
          <Grid item xs={12} >
            <TextField 
              className={classes.contact_input}
              label="Objet" 
              placeholder=""
              fullWidth
              margin="normal"
              variant="outlined"
              required
              onChange={handleChange}
              name="object"
            />
          </Grid>
          <Grid item xs={12} >
            <TextField
              className={classes.contact_input}
              fullWidth
              multiline
              variant="outlined"
              rows={10}
              rowsMax={10}
              label="Ton message" 
              aria-label="message"
              placeholder="C'est à toi, dis-nous tout"
              defaultValue="Bonjour Surboom, c'est trop cool ton concept, je t'<3&#160;!"
              onChange={handleChange}
              name="message"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid 
          className={classes.contact_input_button}
          container
          direction="column"
          alignContent="center"
        >
          <Button
            type="submit"
            variant="contained"
          >
            envoyer
          </Button>
        </Grid>
      </Grid>
      <div data-netlify-captcha />
    </form>
  </Grid>
  );
};

export default ContactForm;
